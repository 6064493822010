import React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
    background-color: #FFF;
    ${props => props.footerStyle}
    z-index: 9;
    width: 100%;
    bottom: 0;
    position: relative;
    height: 58px;
`;

const SupportText = styled.footer`
    text-align: right;
    padding: 20px;
    font-size: 0.9em;
`;

const FooterSolid = ({feature}) => (
    <Footer footerStyle={(feature.layout && feature.layout.styles && feature.layout.styles.footer)}>
        <SupportText>© {new Date().getFullYear()}, CX Systems Powered by <a style={{color: 'unset'}} href={`https://www.mrmsupport.co.za/?mrm_cx_id=${encodeURI(feature._id)}`} >MRM Support</a></SupportText>
    </Footer>
);

export default FooterSolid;
