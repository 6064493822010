import React from 'react';
import { Button } from 'antd';
import { Icon } from 'antd';
import AutoForm from './AutoForm';
import AutoField from './AutoField';
import ErrorsField from './ErrorsField';
import AutoFields from './AutoFields';
import { withSurvey } from './uniforms-survey';

const SurveyAction = props => (
    <Button
        style={{width: '100%'}}
        className={props.type == 'success' ? 'ant-btn-success' :''}
        disabled={props.disabled}
        htmlType="button"
        onClick={props.action}
        size="large"
        loading={props.loading}
        type={props.type ? props.type : 'primary'}
    >
        <Icon type={props.icon} />{props.text}
    </Button>
);

export const FooterButtonsPNSC = (props) => (
    <div style={{textAlign: 'center', paddingTop: 20, marginTop: 20, borderTop: '1px solid #e9e9e9'}}>
        {!(!props.page && !!props.pages)   && (<SurveyAction action={props.onPrev}   disabled={!props.page && !!props.pages}    icon="left-circle-o"  text="Prev"   left />)}
        {!(props.page !== props.pages - 1) && (<SurveyAction action={props.onNext}   disabled={props.page !== props.pages - 1}  icon="check-circle-o" text="Submit" type="primary" right loading={props.saving}/>)}
        {!(props.page === props.pages - 1) && (<SurveyAction action={props.onNext}   disabled={props.page === props.pages - 1}  icon="right-circle-o" text="Next"   right />)}
    </div>
);

export const SurveyForm = withSurvey(props => (
    <AutoForm
        // FIXME: Theres a weird behaviour with validation order when
        //        pressing "Prev"... To investigate later.
        key={props.page}
        model={props.model}
        onChangeModel={(model) => {props.onChange(model);}}
        onSubmit={(model) => {props.onSubmit(model);}}
        ref={props.onForm}
        schema={props.schema}
        showInlineError
        validate="onSubmit"
    >
        <div style={{minHeight: '50vh'}}>
            <AutoFields autoField={AutoField} fields={props.fields} model={props.model} queryKey={props.queryKey}/>
        </div>
        <div>
            <ErrorsField />
        </div>
        <div>
            <FooterButtonsPNSC {...props} />
        </div>
    </AutoForm>
));
