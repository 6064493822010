import AutoForm from 'uniforms/AutoForm';
import PropTypes from 'prop-types';
import filterDOMProps from 'uniforms/filterDOMProps';

import AutoField from './AutoField';
import ValidatedQuickForm from './ValidatedQuickForm';


const Auto = parent =>
  class extends AutoForm.Auto(parent) {
    static Auto = Auto;
  };

const AutoForm_ = Auto(ValidatedQuickForm);

filterDOMProps.register(
    'fieldData',
    'itemProps',
    'info',
    'fieldComponent',
    'editComponent',
    'readOnly',
    'readOnlyComponent',
    'modelDynamic',
    'onChangeModel',
    'queryKey',
    'arrayHistory'
);

// Inject custom AutoField into every ListField and NestField
// NOTE: This is modyfying schema!
const augmentSchema = (schema, model, onChangeModel) => {
    if (schema.__augmented) {
        return;
    }

    schema.__augmented = true;

    if (schema._schema) {
        Object.keys(schema._schema).forEach(name => {
            const definition = schema._schema[name];
            const type = definition.type.definitions[0].type;
            if (definition.props) {
                definition.props.model = model;
            }
            if (type === Array || type === Object) {
                if (typeof definition.uniforms === 'function') {
                    definition.uniforms = {component: definition.uniforms, itemProps: {component: AutoField}};
                } else if (definition.uniforms) {
                    definition.uniforms.itemProps = {...definition.uniforms.itemProps, component: AutoField};
                } else {
                    definition.uniforms = {itemProps: {component: AutoField}};
                }
            }
        });
    } else if (schema.schema.fields) {
        schema.schema.fields.forEach(field => {
            if (field.props) {
                field.props.model = model;
                field.props.onChangeModel = onChangeModel;
            }
            if (field.uniforms) {
                field.uniforms.itemProps = {...field.uniforms.itemProps, component: AutoField};
            } else {
                field.uniforms = {itemProps: {component: AutoField}};
            }
        });
    } else {
        throw new Error('Unrecognized schema.');
    }
};

const Augmented = parent => class AugmentedForm extends parent {
    static Augmented = Augmented;

    static displayName = `Augmented${parent.displayName}`;

    static propTypes = {
        ...parent.propTypes,

        fieldData: PropTypes.object
    };

    constructor (props) {
        augmentSchema(props.schema, props.model, props.onChangeModel);
        super(...arguments);
    }

    componentWillReceiveProps (props) {
        augmentSchema(props.schema, props.model, props.onChangeModel);
        super.componentWillReceiveProps(...arguments);
    }

    getAutoField () {
        return AutoField;
    }

    getChildContextState () {
        return {
            ...super.getChildContextState(),
            fieldData: this.props.fieldData
        };
    }

    getNativeFormProps () {
        const {
            fieldData, // eslint-disable-line no-unused-vars
            ...props
        } = super.getNativeFormProps();
        return props;
    }
};

export default Augmented(AutoForm_);
