import React from 'react';
import connectField    from 'uniforms/connectField';
import {createElement} from 'react';

const ReadOnlyText = (props) => {
    return(
        <div className={'form-bg-light-grey'}>
            <div className={'form-bg-dark-grey'}>
                {createElement(props.tag, {dangerouslySetInnerHTML: {__html: props.html ? props.html : props.label}})}
            </div>
        </div>
    );
}

ReadOnlyText.defaultProps = {
    tag: 'div'
};

export default connectField(ReadOnlyText);
