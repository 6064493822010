import React        from 'react';
import { Checkbox } from 'antd';
import { Icon }        from 'antd';
import { Slider }   from 'antd';
import connectField from 'uniforms/connectField';
import wrapField from './wrapField';
import styled from 'styled-components';
import colorBlend from './color';

const CheckboxContainer = styled.div`
    padding-left: 40px;
    margin-bottom: 0px;
`;

const SliderPosition = styled.div`
    position: relative;
    padding: 0px 40px 0px 40px;

    .anticon {
      position: absolute;
      top: -10px;
      width: 16px;
      height: 16px;
      line-height: 0.7;
      font-size: 30px;
      color: ${props => props.secondry_color};
    }

    .anticon:first-child {
      left: 0;
    }

    .anticon:last-child {
      right: 10px;
    }

    .anticon.anticon-highlight {
      color: ${props => props.primary_color};
    }

    .ant-slider-handle {
      margin-left: -10px;
      margin-top: -8px;
      width: 20px;
      height: 20px;
    }

    .ant-slider-handle:focus {
      outline: none;
    }
`;





class Slider_ extends React.Component {
    constructor () {
        super(...arguments);
        this.onChangeOptional = this.onChangeOptional.bind(this);
        this.state = {
            na: this.props.value === null ? true : false
        }
    }

    onChangeOptional () {
        this.setState({na: !this.state.na});
        this.props.onChange(!this.state.na ? null : this.props.initialValue ? this.props.initialValue :  Math.round((this.props.max - this.props.min) / 2));
    }

    componentWillReceiveProps (props) {
      if (props.value !== null) {
          this.setState({na: false});
      }
    }

    render () {
      const {value, max, min, iconMin, iconMax, optional, onChange, initialValue } = this.props;
      let marks = {};
        marks[min-1] =  {
                        style: {
                        marginTop: 4,
                        fontSize: 10
                      },
                      label: <span><strong>NotSet</strong></span>
                    };
      for (let k = min; k <= max; k++) {
            marks[k] = {
                        style: {
                            marginTop: 2,
                            fontSize: 12
                        },
                        label: <strong>{k}</strong>
                    }
      }
      return wrapField(this.props, (
        <div>
          {!this.state.na &&
              <SliderPosition primary_color={colorBlend(0.4,'#555')} secondry_color={colorBlend(0.8,'#555')}>
                <Icon className={value < (max - min) / 2 && value !== null ? 'anticon-highlight' : null} type={iconMin} />
                <Slider
                  marks={marks}
                  max={max}
                  min={min-1}
                  onChange={(value)=>value < min ? onChange(null) : onChange(value)}
                  value={(value === undefined || value === null) ? initialValue || initialValue === 0 ? initialValue : Math.round((max - min) / 2) : value }
                />
                <Icon className={value > (max - min) / 2 ? 'anticon-highlight' : null} type={iconMax} />
             </SliderPosition>
           }
           {optional &&
             <CheckboxContainer>
                 <Checkbox checked={this.state.na} onChange={this.onChangeOptional} style={{fontSize: '0.6rem'}} >Not Applicable</Checkbox>
             </CheckboxContainer>
           }
        </div>
      ));
    }
}

Slider_.defaultProps = {
    iconMax: 'smile-o',
    iconMin: 'frown-o'
};

export default connectField(Slider_, {ensureValue: false});
