import React from 'react';
import connectField    from 'uniforms/connectField';
import {createElement} from 'react';

import formatFieldToString from './formatFieldToString';

const ReadOnlyTop = (props) => {
    return(
        <div className={'form-bg-light-grey'} style={{paddingBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 0}}>
            <div className={'form-bg-dark-grey'} style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, marginBottom: 0, paddingBottom: 3}}>
                <b>{props.label}</b>: {formatFieldToString(props)}
            </div>
        </div>
    );
}

ReadOnlyTop.defaultProps = {
    tag: 'div'
};

export default connectField(ReadOnlyTop);
