import { createElement } from 'react';
import React from 'react';
import connectField from 'uniforms/connectField';
import { Avatar } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import { Button } from 'antd';
import { Input } from 'antd';
import { Pagination } from 'antd';
import Badge from 'antd/lib/badge';
import wrapField from './wrapField';

// {
// "name": "__fieldname__",
// "type": "string",
// "optional": true,
// "editComponent": "content_list",
// "props": {
//     "label": "Heading for the list",
//     "errorMessage": "none",
//     "query": {
//         "returnType": "array",
//         "query_id": "G9K32mhr3TA6T6fSj",
//         "queryProp": "listElements",
//         "linkedFields": ["channel"]
//     },
//     "listHeight": 400,
//     "listElements": [{
//         "avatar": "NONE",
//         "backgroundColor": "avatar color",
//         "title": "No Items Found",
//         "description": '-',
//         "key": "item key or id",
//         "content": "A line of html text",
//         "gridContent": [
//             ['', 'title 1', 'title 2'],
//             ['sales', 1, 2],
//             ['target', 1.234, 2.0002]
//
//         ]
//     }]
// }


function findString (searchText, target, k) {
    if (!target) {
        return 0;
    }
    let t = target;
    if (typeof target !== 'string') {
        t = t.toString();
    }
    let tL = t.toLowerCase();
    let score = tL.indexOf(searchText.trim().toLowerCase());
    return score === -1 ? 0 : 1000000 - k*score;
}

function processSearch (searchText, allData) {
    if (!searchText || searchText.trim() === '' || searchText.length === 0)
        return allData;
    let singleRes = null;
    let score = 0;
    const results = [];
    for(const obj of allData) {
        score = -1;
        //only look at title, description, content, gridContent
        score = score + findString(searchText, obj['title'], 1);
        score = score + findString(searchText, obj['description'], 3);
        score = score + findString(searchText, obj['content'], 10);
        if (obj['gridContent']) {
            obj['gridContent'].forEach((row)=>{
                row.forEach((col)=>{
                    score = score + findString(searchText, col, 15);
                })
            });
        }
        if(score <= -1) continue;
        obj.score_content_list = score;
        results.push(obj);
    }
    return results.sort((a, b)=> b.score_content_list - a.score_content_list);
}


const listItems = (onChange, colSpan, item, i, selected, searchText) => {
    return (
        <div key={item._id ? item._id : item.key ? item.key : i} onClick={()=>onChange(item._id ? item._id : item.key ? item.key : i, item)}>
            <div className="form-bg-light-grey-hover" style={{padding: 15, cursor: !selected && 'pointer', backgroundColor: selected && '#e9e9e9'}}>
                {(item.avatar || item.avatar === 0) && item.badge ?
                    <div style={{float: 'left'}}>
                        <Badge dot><Avatar shape="square" size="large" style={{ backgroundColor: item.backgroundColor }} >{item.avatar}</Avatar></Badge>
                    </div> :
                    (item.avatar || item.avatar === 0) && <div style={{float: 'left'}}>
                        <Avatar shape="square" size="large" style={{ backgroundColor: item.backgroundColor }} >{item.avatar}</Avatar>
                    </div>
                }
                <div style={{lineHeight: 1.3, margin: '5px 45px', minHeight: 40}}>
                    {item.title && <div style={{margin: 5}}>{createElement('span', {dangerouslySetInnerHTML: {__html: item.title}})}</div>}
                    {item.description && <div style={{color: '#b3b3b3', fontSize: 'smaller', margin: 5}}>{createElement('span', {dangerouslySetInnerHTML: {__html: item.description}})}</div>}
                    {item.content && <div style={{fontSize: 'smaller', margin: 5}}>{createElement('span', {dangerouslySetInnerHTML: {__html: item.content}})}</div>}
                    {item.gridContent && <div style={{fontSize: 'smaller', margin: 5}}>{item.gridContent.map((row, i)=>{
                        if (row.length === 0){
                            return (<div style={{margin: 5}}></div>);
                        }
                        return (
                            <Row key={row.key ? row.key : i} gutter={3} >
                                {row.map((col, k)=>(
                                    <Col key={k} span={colSpan && Array.isArray(colSpan) ? colSpan[k] : Math.floor(24/(row.length))}>{createElement('span', {dangerouslySetInnerHTML: {__html: col}})}</Col>
                                ))}
                            </Row>
                        );
                    })}</div>}
                </div>
            </div>
            <div style={{margin: '15px 0px', borderTop: '1px solid #d9d9d9'}}></div>
        </div>
    );
}


class ContentList extends React.Component {
    constructor (props) {
        super(props);
        this.state = {search: '', allData: [], data: [], page: 1, pageSize: 10};
        this.filterData = this.filterData.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.pageItems = this.pageItems.bind(this);
    }

    componentWillMount () {
        this.setState({ data: this.props.listElements });
    }

    filterData (e) {
        this.setState({data: processSearch(e.target.value, this.props.listElements), search: e.target.value});
    }

    componentWillReceiveProps (props) {
        this.setState({data: processSearch(this.state.search, props.listElements)});
    }

    onSelect (key, item) {
        if (this.props.buildModel && item){
            let obj = {...this.props.modelDynamic};
            if (Array.isArray(this.props.buildModel)){
                for (let k in this.props.buildModel) {
                    obj[this.props.buildModel[k]] = item[this.props.buildModel[k]];
                }
            } else {
                obj = item;
            }
            obj[this.props.name] = key;
            this.props.onChangeModel(obj);
        } else {
            this.props.onChange(key);
        }
    }
    pageItems (page, pageSize) {
        this.setState({page});
    }

    render () {
        const { listElements, value, label, listHeight, enableClear, enableSearch, pagination } = this.props;
        const { data, search, page, pageSize} = this.state;
        if (!value) {
            return (
                <div>
                    <div style={{whiteSpace: 'normal', textAlign: 'left'}} >{label}</div>
                    <div>
                        {enableSearch && <Input.Search ref="search" placeholder="Input search text" onChange={this.filterData} style={{ width: 200 }} value={search}/>}
                    </div>
                    <div style={{margin: '15px 0px', borderTop: '1px solid #d9d9d9'}}></div>
                    {data && data.length ?
                        <div style={{overflowY: 'scroll', maxHeight: listHeight}}>
                            {data.slice(pagination ? (page-1)*pageSize : 0, pagination ? (page)*pageSize+pageSize > data.length ? data.length : (page-1)*pageSize+pageSize : data.length ).map((item, i)=>listItems(this.onSelect, this.props.colSpan, item, i, false, search))}
                        </div>
                        : <div style={{textAlign: 'center', color: '#d9d9d9', fontWeight: 600, fontSize: 'larger'}} >No Data</div>
                    }
                    { pagination && (data &&  data.length && !search || search.trim() === '' || search.length === 0 ?
                        <div style={{font: 'smaller', textAlign: 'right', color: '#b3b3b3', paddingTop: 15}}>Click to select - Showing {data.length < (page)*pageSize ? data.length : (page-1)*pageSize+1} to {(page)*pageSize+pageSize > data.length ? data.length : (page-1)*pageSize+pageSize+1} items of {data.length} - <Pagination defaultCurrent={1} total={data.length} onChange={this.pageItems}/></div>
                        :
                        <div style={{font: 'smaller', textAlign: 'right', color: '#b3b3b3', paddingTop: 15}}>Click to select - {data.length} items of {listElements.length} matched search - <Pagination defaultCurrent={1} total={data.length} onChange={this.pageItems} /></div>
                    )}
                    { !pagination && (data &&  data.length && !search || search.trim() === '' || search.length === 0 ?
                        <div style={{font: 'smaller', textAlign: 'right', color: '#b3b3b3', paddingTop: 15}}>Click to select - One of items {data.length} items</div>
                        :
                        <div style={{font: 'smaller', textAlign: 'right', color: '#b3b3b3', paddingTop: 15}}>Click to select - {data.length} items of {listElements.length} matched search</div>
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{whiteSpace: 'normal', textAlign: 'left'}} >{label}</div>
                    {enableClear && <div><Button onClick={()=>{ this.setState({search: ''}); this.props.onChange(null);}}>Clear Selected</Button></div> }
                    <div style={{margin: '15px 0px', borderTop: '1px solid #d9d9d9'}}></div>
                    {data &&  data.length ?
                        <div style={{overflowY: 'scroll'}}>
                            {data.filter((i)=>i.key === value || i._id === value).map((item, i)=>listItems(this.onSelect, this.props.colSpan, item, i, true, search))}
                        </div>
                        : <div style={{textAlign: 'center', color: '#d9d9d9', fontWeight: 600, fontSize: 'larger'}} >No Data</div>
                    }
                    { data && data.length && <div style={{font: 'smaller', textAlign: 'right', color: '#b3b3b3', paddingTop: 15}}>1 of {listElements.length} items selected</div>}
                </div>
            );
        }
    }

};


ContentList.defaultProps = {
    pagination: false,
    listElements: [],
    label: 'No Label',
    listHeight: 400
};

export default connectField(ContentList);
