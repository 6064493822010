import Bridge from 'uniforms/Bridge';
import filterDOMProps from 'uniforms/filterDOMProps';
import PropTypes from 'prop-types';
import React from 'react';
import invariant from 'fbjs/lib/invariant';
import { siftQueryMethod } from './patterns';

// register all additional props to filterDOMProps
filterDOMProps.register(
    'query',
    'query_id',
    'dashId',
    'model',
    'dispatch'
);

export class SurveyBridge extends Bridge {
    constructor (schema) {
        super();

        invariant(schema, 'schema is required');
        invariant(schema.fields, 'schema.fields is required');
        invariant(Array.isArray(schema.fields), 'schema.fields must be an array');

        this.schema = Object.assign({}, schema);
        this.schema.fields = this.schema.fields.map(field => {
            let type;

            switch (field.type) {
            case 'date':    type = Date;    break;
            case 'array':   type = Array;   break;
            case 'number':  type = Number;  break;
            case 'object':  type = Object;  break;
            case 'string':  type = String;  break;
            case 'boolean': type = Boolean; break;
            default: invariant(false, 'field %s have invalid type', field.name);
            }

            return Object.assign({}, field, {type});
        });
    }

    static check () {
        return false;
    }

    getError (name, error) {
        return (error && error.details.find(field => field.name === name)) || null;
    }

    getErrorMessage (name, error) {
        const  scoped = this.getError(name, error);
        return (scoped && scoped.message) || '';
    }

    getErrorMessages (error) {
        return error ? error.details.map(detail => JSON.stringify(detail)) : [];
    }

    getField (name) {
        const path = name.replace(/\.\d+/g, '.$');
        const field = this.schema.fields.find(field => field.name === path);

        invariant(field, 'Field not found in schema: "%s"', name);

        return field;
    }

    getInitialValue (name, props = {}) {
        const field = this.getField(name);
        if (field.type === Array && !field.props.allowedValues) {
            const item = this.getInitialValue(name + '.0');
            const items = Math.max(
                props.initialCount || 0,
                field.minCount     || 0
            );

            return [...Array(items)].map(() => item);
        }

        if (field.type === Object) {
            return {};
        }

        return field.defaultValue;
    }

    getProps (name) {
        const field = this.getField(name);
        return Object.assign(
            {
                label: name,
                optional: !!field.optional,
                fieldComponent: field.fieldComponent,
                editComponent: field.editComponent,
                readOnly: field.readOnly,
                readOnlyComponent: field.readOnlyComponent
            },
            field.props
        );
    }


    getSubfields (name) {
        const fields = this.schema.fields.map(field => field.name);

        if (name) {
            const path = name.replace(/\.\d+/g, '.$');
            return fields
                .filter(field => field.startsWith(path + '.'))
                .map(field => field.replace(path + '.', ''))
                .filter(Boolean)
                .filter(field => !field.startsWith('$'))
            ;
        }

        return fields.filter(field => !field.includes('.$'));
    }

    getType (name) {
        return this.getField(name).type;
    }

    getValidator () {
        // TODO: No validation of array or object items yet.
        return model => {
            const details = this.schema.fields.map(field => {
                if (!field.visible(model) || field.name.includes('.')) // pass validation for array and object item
                    return undefined;
                if (!field.optional && (model[field.name] === '' || model[field.name] === undefined)) {
                    // FIXME: It's not good, as it mutates model.
                    const initial = this.getInitialValue(field.name);
                    if (initial !== '' && initial !== undefined) {
                        model[field.name] = this.getInitialValue(field.name);
                        return undefined;
                    }

                    return {name: field.name, type: 'required'};
                }
                if (field.regEx && !field.regEx.test(model[field.name]))
                    return {name: field.name, type: 'regEx'};
                return undefined;
            }).filter(Boolean);

            if (details.length)
                throw {details};
        };
    }
}

const EmptySchema = new SurveyBridge({fields: []});

const visibilityCheckerFallback = () => true;
const visibilityChecker = query => {
    if (!query) {
        return visibilityCheckerFallback;
    }

    try {
        return siftQueryMethod(query);
    } catch (error) {
        return visibilityCheckerFallback;
    }
};

const readOnlyCheckerFallback = () => false;
const readOnlyChecker = query => {
    if (!query) {
        return readOnlyCheckerFallback;
    }

    try {
        return siftQueryMethod(query);
    } catch (error) {
        return readOnlyCheckerFallback;
    }
};

export class SurveyManager {
    constructor ({fields, model = {}, pages}) {
        invariant(fields, 'fields is required');
        invariant(model, 'model is required');
        invariant(pages, 'pages is required');
        invariant(Array.isArray(pages), 'pages must be an array');

        this._fields = Object.entries(fields).reduce(
            (fields, [name, field]) => Object.assign(
                fields,
                {
                    [name]: {
                        ...field,
                        visible: visibilityChecker(field.visibleIf),
                        readOnly: readOnlyChecker(field.readOnlyIf)
                    }
                }),
            {}
        );

        this._pages = pages.map(page => {
            const fields = page.fields.map(field => ({name: field, ...this._fields[field]}));

            // Add array elements.
            Object.entries(this._fields).forEach(([name, field]) => {
                if (name.includes('.')) {
                    fields.push({name, ...field});
                }
            });

            return {
                ...page,
                visible: visibilityChecker(page.visibleIf),
                schema: new SurveyBridge({fields})
            };
        });
    }

    // NOTE: Shallow copy of model.
    init ({...model}) {
        Object.entries(this._fields).forEach(([name, field]) => {
            if (model[name] === undefined && field.defaultValue !== undefined)
                model[name] = field.defaultValue;
        });

        return {model, page: this.pageNext(-1, model)};
    }

    pageNext (page, model) {
        let next = page + 1;
        while (next < this.pages() && this.visible(next, model).length === 0)
            next = next + 1;

        if (next === this.pages())
            next = next - 1;

        return next;
    }

    pagePrev (page, model) {
        let prev = page - 1;
        while (prev > 0 && this.visible(prev, model).length === 0)
            prev = prev - 1;

        return prev;
    }

    pages () {
        return this._pages.length;
    }

    schema (page) {
        if (this._pages[page] === undefined)
            return EmptySchema;

        return this._pages[page].schema;
    }

    visible (page, model) {
        if (this._pages[page] === undefined)
            return [];

        return this._pages[page].visible(model)
            ? this._pages[page].schema.schema.fields
                .filter(field => !field.name.includes('.') && field.visible(model))
                .map(field => field.name)
            : []
        ;
    }
}

const addStaticValues = (model, fields) => {
    const statics = {};
    const values = Object.keys(fields).filter(field=>fields[field].props && fields[field].props.staticValue).map(field=>({name: field, value: fields[field].props.staticValue}));
    values.forEach(val=>statics[val.name] = val.value);
    return {...model, ...statics};
}

export const withSurvey = Component => class extends React.Component {
    static defaultProps = {
        model: {},
        onPage (/* page, model, direction */) {},
        onSubmit (/* model */) {}
    };

    static propTypes = {
        model:    PropTypes.object.isRequired,
        survey:   PropTypes.instanceOf(SurveyManager).isRequired,
        onPage:   PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    constructor () {
        super(...arguments);
        this.form  = null;
        this.state = this.props.survey.init(this.props.model);
    }

    onForm = ref => this.form = ref;

    onNext = () => this.setState(state => ({page: this.props.survey.pageNext(state.page, state.model)}), () => this.props.onPage(this.state.page, this.state.model, 'forward'));
    onPrev = () => this.setState(state => ({page: this.props.survey.pagePrev(state.page, state.model)}), () => this.props.onPage(this.state.page, this.state.model, 'backward'));

    onChange = model => this.setState(state => ({model: Object.assign({}, state.model, model)}));
    onSubmit = model => {
        const page = this.state.page;
        const pages = this.props.survey.pages() - 1;
        const pageNext = this.props.survey.pageNext(this.state.page, model);
        if (page === pages && pageNext === pages) {
            model = addStaticValues(model, this.props.survey._fields);
            const result = Object.entries(this.props.survey._fields).reduce(
                (result, field) =>
                    // TODO: Should undefined be skipped?
                    field[1].visible(model) && model[field[0]] !== undefined
                        ? Object.assign(result, {[field[0]]: model[field[0]]})
                        : result,
                {}
            );
            this.props.onSubmit(result);

        } else {
            this.onNext();
        }
    };

    onSubmitTrigger = () => this.form && this.form.submit();

    render () {
        const  props = Object.assign({}, this.props);
        delete props.onPage;
        delete props.survey;
        // NOTE: Both props.model and props.onSubmit will be overwritte.

        return (
            <Component
                {...props}
                fields={this.props.survey.visible(this.state.page, this.state.model)}
                model={this.state.model}
                onChange={this.onChange}
                onForm={this.onForm}
                onNext={this.onSubmitTrigger}
                onPrev={this.onPrev}
                onSubmit={this.onSubmit}
                page={this.state.page}
                pageNext={this.props.survey.pageNext(this.state.page, this.state.model)}
                pages={this.props.survey.pages()}
                schema={this.props.survey.schema(this.state.page)}
            />
        );
    }
};
