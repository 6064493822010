import React          from 'react';
import { Button }     from 'antd';
import connectField   from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';

const ListAdd = ({
    disabled,
    parent,
    value,
    modelDynamic,
    addItem,
    ...props
}) => {
    const limitNotReached = !disabled && !(parent.maxCount <= parent.value.length);
    if (!addItem(modelDynamic)) {
        return null;
    }
    return (
        <div style={{marginTop: '10px'}}>
            <Button
                disabled={!limitNotReached || disabled}
                onClick={() => limitNotReached && parent.onChange(parent.value.concat([value]))}
                {...filterDOMProps(props)}
            />
        </div>
    );
};

ListAdd.defaultProps = {
    icon: 'plus-square-o',
    size: 'small',
    style: {width: '100%'},
    type: 'dashed'
};

export default connectField(ListAdd, {includeParent: true, initialValue: false});
