import React          from 'react';
import { Button }         from 'antd';
import connectField   from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';

const TabsAdd = ({disabled, parent, value, ...props}) => {
    const limitNotReached = !disabled && !(parent.maxCount <= parent.value.length);

    return (
        <Button
            disabled={!limitNotReached || disabled}
            onClick={() => limitNotReached && parent.onChange(parent.value.concat([value]))}
            {...filterDOMProps(props)}
        />
    );
};

TabsAdd.defaultProps = {
    icon: 'plus',
    size: 'small'
};


export default connectField(TabsAdd, {includeParent: true, initialValue: false});
