import React from 'react';
import styled from 'styled-components';

const AppHeader = styled.div`
    color: white;
    ${props => props.headerStyle}
    z-index: 9;
    width: 100%;
    height: 100px;
`;

const Logo = styled.div`
    text-align: centre;
    padding: 1.4em 0px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    ${props => props.logoStyle}
`;

const HeaderSolid = ({ feature }) => (
    <AppHeader headerStyle={feature.layout && feature.layout.styles && feature.layout.styles.header} >
        <Logo logoStyle={(feature.layout && feature.layout.styles && feature.layout.styles.logo)}>
            {feature.layout && feature.layout.logo_html ?
                <div dangerouslySetInnerHTML={{__html: feature.layout.logo_html}} />
            :
                <img width='100%' src={feature.layout && feature.layout.logo_img ? feature.layout.logo_img : 'https://s3.amazonaws.com/mrmapp-public/companies/MRMSUPPORT/MRMSUPPORT_logo.png'} />
            }
        </Logo>
    </AppHeader>
);

export default HeaderSolid;
