import React          from 'react';
import { Checkbox }       from 'antd';
import { Radio }          from 'antd';
import { Select }         from 'antd';
import connectField   from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';

import wrapField from './wrapField';

const renderCheckboxes = props =>
    props.fieldType === Array ? (
        <Checkbox.Group
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            onChange={value => props.onChange(value)}
            options={props.allowedValues.map(String)}
            value={props.value.map(String).map(props.transform || String)}
            {...filterDOMProps(props)}
        />
    ) : (
        <Radio.Group
            disabled={props.disabled}
            name={props.name}
            onChange={event => props.onChange(event.target.value)}
            value={props.value}
            buttonStyle='solid'
            size='small'
            {...filterDOMProps(props)}
        >
            {props.allowedValues.filter(item => !item.hide).map(value =>
                <Radio
                    key={value}
                    value={value}
                >
                    {props.transform ? props.transform(value) : value}
                </Radio>
            )}
        </Radio.Group>
    )
;

const options = (props) => !!props.allowedValues[0] && !!(props.allowedValues[0] instanceof Object) ?
    props.allowedValues.filter(item => !item.hide).map(obj =>
        <Select.Option key={obj.value} value={obj.value} search={obj.label}>
            {props.transform ? props.transform(obj.label) : obj.label}
        </Select.Option>
    ) :
    props.allowedValues.map(value =>
        <Select.Option key={value} value={value} search={value}>
            {props.transform ? props.transform(value) : value}
        </Select.Option>
);

const renderSelect = props => {
    return <Select
        allowClear={!props.required}
        disabled={props.disabled}
        id={props.id}
        mode={props.fieldType === Array ? 'multiple' : undefined}
        name={props.name}
        onChange={value => props.onChange(value)}
        placeholder={props.placeholder}
        ref={props.inputRef}
        value={props.fieldType === Array ? props.value || [] : '' + (props.value || '')}
        size={props.size ? props.size : 'default'}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...filterDOMProps(props)}
    >
        {options(props)}
    </Select>;
};

const Select_ = props =>
wrapField(props, (
    props.checkboxes
        ? renderCheckboxes(props)
        : renderSelect(props)
));

Select_.displayName = 'Select';

export default connectField(Select_);
