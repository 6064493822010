import React          from 'react';
import { Form }          from 'antd';
import { Icon }           from 'antd';
import { Tooltip }       from 'antd';
import filterDOMProps from 'uniforms/filterDOMProps';
import styled from 'styled-components';

const Label = styled.div`
    text-align: left;
    vertical-align: middle;
    line-height: 30px;
    display: inline-block;
    overflow: hidden;
    white-space: normal;
`;


export default function wrapField ({
    colon,
    error,
    errorMessage,
    id,
    info,
    label,
    labelCol,
    required,
    showInlineError,
    wrapperCol
}, children) {
    const labelNode = !!label && (
        <Label>
            {label}
            {!!info && (
                <span>
                    &nbsp;
                    <Tooltip title={info}>
                        <Icon type="question-circle-o" />
                    </Tooltip>
                </span>
            )}
        </Label>
    );

    return (
        <Form.Item
            colon={false}
            hasFeedback
            help={showInlineError && error && errorMessage}
            htmlFor={id}
            label={labelNode}
            labelCol={labelCol}
            required={required}
            validateStatus={error ? 'error' : undefined}
            wrapperCol={wrapperCol}
        >
            {children}
        </Form.Item>
    );
}

filterDOMProps.register(
    'colon',
    'labelCol',
    'wrapperCol'
);
