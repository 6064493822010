import React from 'react';
import { withCookies } from 'react-cookie';

import CxSurveyForm from '../components/CxSurveyForm';
import Layout from '../components/Layout';
import loadFeature from '../components/loadFeature';
import withSplashScreen from '../components/withSplashScreen';
import '../style.css';

const IndexPage = ({feature, model, cookies, location}) => {
    return (
        <Layout feature={feature} model={model}>
            <CxSurveyForm feature={feature} model={model} cookies={cookies} location={location} />
        </Layout>
    );
}
// {_id: '2PhizueAASNeSWo66'}
const App = withCookies(loadFeature(IndexPage));
export default withSplashScreen(App);
