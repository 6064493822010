import React from 'react';
import axios from 'axios';


export const makeId = () => {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 30; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const upsetData = (feature_path, variable_model, save_state, cookies, success, error) => {
  if (!cookies.get('mrm_feature_path_'+feature_path)){
    cookies.set('mrm_feature_path_'+feature_path, makeId(), { path: '/' });
  }
  axios({
    method: 'post',
    url: 'https://api.mrmupload.com/cx',
    //url: 'http://127.0.0.1:5000/cx',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'j9X84orYgX9cIR40l82rJ58oAcf4S6LR1zJvttsy'
    },
    data: {
      variable_model: {...variable_model, mrm_cookie_id: cookies.get('mrm_feature_path_'+feature_path)},
      feature_path,
      save_state
    }
  }).then(res => {
      success(res.data.feature, res.data.product_model);
      console.log(res);
  }).catch(err => {
      error(err)
      console.log(err);
  });
}
