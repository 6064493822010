import React from 'react';
import connectField    from 'uniforms/connectField';
import {createElement} from 'react';

import formatFieldToString from './formatFieldToString';

const ReadOnlyBottom = (props) => {
    return(
        <div className={'form-bg-light-grey'} style={{paddingTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, margin: 0}}>
            <div className={'form-bg-dark-grey'} style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: 0, paddingTop: 3}}>
                <b>{props.label}</b>: {formatFieldToString(props)}
            </div>
        </div>
    );
}

ReadOnlyBottom.defaultProps = {
    tag: 'div'
};

export default connectField(ReadOnlyBottom);
