import connectField    from 'uniforms/connectField';
import {createElement} from 'react';

const Static = ({html, tag, className, style}) => (
    createElement(tag, {dangerouslySetInnerHTML: {__html: html}, className: className, style: style})
);

Static.defaultProps = {
    tag: 'div',
    className: '',
    style: {}
};

export default connectField(Static);
