import React          from 'react';
import { DatePicker }     from 'antd';
import connectField   from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';
import moment         from 'moment';

import wrapField from './wrapField';

const Date_ = props =>
    wrapField(props, (
        <DatePicker
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            onChange={value => {
                if (value && props.showTime) {
                    return props.onChange(value && value.toDate());
                } else {
                    let date = value.toDate();
                    return props.onChange(value && new Date(date.getFullYear(), date.getMonth(), date.getUTCDate(), 0, -1*date.getTimezoneOffset() ));
                }
            }
            }
            placeholder={props.placeholder}
            ref={props.inputRef}
            value={props.value && moment(props.value)}
            {...filterDOMProps(props)}
        />
    ))
;

Date_.displayName = 'Date';

Date_.defaultProps = {
    showTime: false,
    style: {width: '100%'}
};

export default connectField(Date_, {ensureValue: false});
