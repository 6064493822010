import JSON5 from 'json5';
import sift from 'sift';
import format from 'string-format-patterns';
import {parseObjDatesTransverse} from './parseDates';

format.extend(String.prototype, {});

// evaluate if a string entered is a valid query string
export const siftQueryValidator = () => {
    if (this.value === true || this.value === 'true') {
        return undefined;
    }
    if (this.value === false || this.value === 'false') {
        return undefined;
    }
    if (!this.value) {
        return undefined;
    }
    try {
        sift(parseObjDatesTransverse(JSON5.parse(this.value)));
        return undefined;
    } catch (error) {
        // TODO: Custom error should be used.
        return 'required';
    }
}

// return a function which can evaluate a data object against the query str and return true or false
export const siftQueryMethod = (queryStr) => {
    if (queryStr === true || queryStr === 'true') {
        return (queryStr)=>true;
    }
    if (queryStr === false || queryStr === 'false') {
        return (queryStr)=>false;
    }
    return sift(parseObjDatesTransverse(JSON5.parse(queryStr)));
}

// create the selector object, normally to send to mongo
// this function does sort of the opposite of parseTextData returns json
export const subSelectorJson = (dataObj, selector) => {
    return parseObjDatesTransverse(JSON5.parse(selector.format(dataObj)));
}
