import React from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import get from 'lodash.get';

import { SurveyForm } from './uniforms/SurveyForm';
import { safeSurveyManager } from './uniforms/safeSurveyManager';
import { subLabelFields } from './uniforms/subLabelFields';
import { upsetData } from './dataFunctions';
import Message from './Message';

const Container = styled.div`
    margin: 0 auto;
    text-align: center;
    padding: 10px;
    width: 100%;
`;

const ContainerCenter = styled.div`
    background-color: #FFF;
    ${props => props.styleSurvey}
    display: inline-block;
    text-align: left;
    width: 90%;
    max-width: 600px;
    margin: 50px 0px 100px 0px;
`;

class CaptureSurvey extends React.Component {
    constructor (props) {
        super(props);
        this.state = {survey: safeSurveyManager(props.survey)};
    }
    componentWillReceiveProps (props) {
        this.setState(() => ({survey: safeSurveyManager(props.survey)}));
    }
    // NOTE: It won't react on non-survey prop change.
    shouldComponentUpdate (props, state) {
        return (this.state.survey.__cached !== state.survey.__cached);
    }
    //footerButtons={footer === 'none' ? FooterButtonsNone : footer === 'close' ? FooterButtonsSaveOff : FooterButtonsPNSCL}
    //
    render () {
        const {onPage, onSubmit, onCancel, model, saving, footer, queryKey} = this.props;
        return(
            <SurveyForm
                key={this.state.survey.__cached}
                queryKey={queryKey}
                model={model}
                onPage={onPage}
                onSubmit={onSubmit}
                onCancel={onCancel}
                saving={saving}
                onSubmit={footer === 'none' ||  footer === 'close' ? ()=>null : onSubmit}
                survey={this.state.survey}
            />
        );
    }
}

class CxSurvey extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
          done: false,
          saving: false,
          err: false,
          model: props.model
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onPage = this.onPage.bind(this);
        this.errorSubmit = this.errorSubmit.bind(this);
        this.successSubmit = this.successSubmit.bind(this);
    }
    onPage (page, model, direction) {
    }
    onCancel () {
    }
    onSubmit (model) {
        this.setState({saving: true, model});
        upsetData(this.props.feature._id, {...queryString.parse(this.props.location.search), ...this.props.model, ...model}, 'submit', this.props.cookies, this.successSubmit, this.errorSubmit);
    }
    successSubmit (feature, model) {
        this.setState({saving: false, done: true, model: model});
    }
    errorSubmit (err) {
        this.setState({saving: false, done: true, err: err});
    }

    render () {
      const { feature, model } = this.props;
      if (this.state.saving) {
        return <Message text='Saving...' icon='loading' />;
      }
      if (this.state.done && !this.state.err) {
        return <Message text={get(this.state.model, 'model_status.message', 'Thank you! Your feedback has been submitted.')} icon={get(this.state.model, 'model_status.icon', 'check-circle')}  />;
      }
      if (this.state.err) {
        // handle problematic IE
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        if(this.state.err === 200 && isIE)
          return <Message text={'Thank you! Your feedback has been submitted.'} icon='check-circle' />;
        return <Message text={'Unsuccessful response submission.'} icon='close-circle' />;
      }
      return (
        <Container>
          <ContainerCenter styleSurvey={(feature.layout && feature.layout.styles && feature.layout.styles.survey)} >
            <CaptureSurvey
                key={0}
                queryKey={'0'}
                onPage={this.onPage}
                onCancel={this.onCancel}
                onSubmit={this.onSubmit}
                saving={false}
                savingNote="Saving"
                survey={{...subLabelFields({}, feature)}}
                user={{}}
                model={model}
            />
          </ContainerCenter>
        </Container>
      );
    }
}

export default CxSurvey;
