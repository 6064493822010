import React, {Component} from 'react';
import Loading from '../components/Loading';


function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 1500);
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading)
        return <Loading />;

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withSplashScreen;
