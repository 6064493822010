import React          from 'react';
import { Button }         from 'antd';
import { Tabs }           from 'antd';
import connectField   from 'uniforms/connectField';
import joinName       from 'uniforms/joinName';
import wrapField      from './wrapField';
import AutoField      from './AutoField';

import TabsAddField from './TabsAddField';

class Tabs_ extends (React.Component) {
    constructor () {
        super(...arguments);

        this.state = {active: this.props.value && this.props.value.length ? 0 : undefined};

        this.onActive   = this.onActive.bind(this);
        this.onMoveNext = this.onMoveNext.bind(this);
        this.onMovePrev = this.onMovePrev.bind(this);
        this.onRemove   = this.onRemove.bind(this);
    }

    componentWillReceiveProps ({value: valueNext}) {
        const active    = this.state.active;
        const valuePrev = this.props.value;

        if (valueNext && valueNext.length) {
            if (active === undefined) {
                this.setState({active: 0});
            } else if (active >= valueNext.length || (valuePrev && valuePrev.length === valueNext.length - 1)) {
                this.setState({active: valueNext.length - 1});
            }
        } else {
            this.setState({active: undefined});
        }
    }

    onActive (active) {
        this.setState({active: parseInt(active, 10)});
    }

    onMoveNext () {
        if (this.props.disabled || this.state.active === undefined || this.state.active >= this.props.value.length) {
            return;
        }

        // Do not mutate props!
        const value = this.props.value.slice();
        value.splice(this.state.active + 1, 0, value.splice(this.state.active, 1)[0]);

        this.props.onChange(value);
        this.setState({active: this.state.active + 1});
    }

    onMovePrev () {
        if (this.props.disabled || this.state.active === undefined || this.state.active === 0) {
            return;
        }

        // Do not mutate props!
        const value = this.props.value.slice();
        value.splice(this.state.active - 1, 0, value.splice(this.state.active, 1)[0]);

        this.props.onChange(value);
        this.setState({active: this.state.active - 1});
    }

    onRemove () {
        if (this.props.disabled || this.state.active === undefined) {
            return;
        }

        this.props.onChange(
            [].concat(this.props.value.slice(0,  this.state.active))
                .concat(this.props.value.slice(1 + this.state.active))
        );
    }

    render () {
        const operationsDisabled = this.props.disabled || this.state.active === undefined;
        const operations = (
            <Button.Group size="small">
                {!this.props.noAddButton && (<TabsAddField initialCount={this.props.initialCount} name={joinName(this.props.name, '$')} />)}
                {!this.props.noRemoveButton && (<Button icon="minus" disabled={operationsDisabled} onClick={this.onRemove} />)}
                <Button icon="caret-left" disabled={operationsDisabled} onClick={this.onMovePrev} />
                <Button icon="caret-right" disabled={operationsDisabled} onClick={this.onMoveNext} />
            </Button.Group>
        );

        return wrapField(this.props,
            <div className="antd-uni-tabs" >
                <Tabs activeKey={'' + this.state.active} onChange={this.onActive} tabBarExtraContent={operations}>
                    {this.props.value instanceof Array && (this.props.value.map((item, index) =>
                        (<Tabs.TabPane key={'' + index} tab={'' + (index + 1)}>
                            <AutoField label={null} name={joinName(this.props.name, index)} {...this.props.itemProps} />
                        </Tabs.TabPane>)
                    ))}
                </Tabs>
            </div>
        );
    }
}

Tabs_.displayName = 'Tabs';

export default connectField(Tabs_, {includeInChain: false});
