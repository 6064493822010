import PropTypes       from 'prop-types';
import {createElement} from 'react';

import AutoField from './AutoField';

const AutoFields = ({autoField, element, fields, omitFields, model, queryKey, ...props}, {uniforms: {schema}}) =>
    createElement(
        element,
        props,
        (fields || schema.getSubfields())
            .filter(field => omitFields.indexOf(field) === -1)
            .map(field => createElement(autoField, {key: field, name: field, modelDynamic: model, queryKey: queryKey}))
    )
;

AutoFields.contextTypes = AutoField.contextTypes;

AutoFields.propTypes = {
    autoField: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    element:   PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

    fields:     PropTypes.arrayOf(PropTypes.string),
    omitFields: PropTypes.arrayOf(PropTypes.string)
};

AutoFields.defaultProps = {
    autoField: AutoField,
    element: 'div',
    queryKey: '0',
    omitFields: []
};

export default AutoFields;
