import moment from 'moment';

export const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z/;

const traverse = (obj, func, regex) => {
    for (let i in obj) {
        obj[i] = func.apply(this, [i, obj[i], regex]);
        if (obj[i] !== null && typeof(obj[i])=='object') {
            traverse(obj[i], func, regex);
        } else if (obj[i] !== null && obj[i] instanceof Array) {
            obj[i].forEach( aobj =>{
                traverse(aobj, func, regex);
            });
        }
    }
}

const parseDate = (key, value, regexp) => {
    return typeof value === 'string' && dateRegex.test(value) ? new Date(value) : value;
}

export const parseObjDatesTransverse = (obj) => {
    traverse(obj, parseDate, dateRegex)
    return obj;
}

export function getDateFields (data) {
    const datefields = [];
    for (let key in data[0]) {
        if (typeof data[0][key] === 'string' &&
            dateRegex.test(data[0][key])) {
            datefields.push(key);
        }
    }
    return datefields;
}

export function parseObjDates (obj, datefields) {
    return datefields.reduce((object, field) =>
        Object.assign(object, {[field]: parseDate(0, obj[field])}),
    {...obj}
    );
}


export function parseDates (Arr, datefields) {
    return Arr.map(obj=>parseObjDates(obj,datefields));
}

export function getAndParseDates (Arr) {
    const datefields = getDateFields(Arr);
    if (datefields.length === 0) {
        return Arr;
    }
    return Arr.map(obj=>parseObjDates(obj,datefields));
}

export function parseObjDatesAg (obj, datefields) {
    return {...obj};
    //return datefields.reduce((object, field) =>
    //Object.assign(object, {[field]: obj[field]}),
    //Object.assign(object, {[field]: moment(obj[field]).toDate()}),
    //    {...obj}
    //);
}



// polyfill Date to Show names
export function extendDates () {
    Date.prototype.format = function(format) {
        if (format && /^'(.+?)'$|^"(.+?)"$/g.test(format)) {
            return moment(this).utc().format(format.substring(1, format.length - 1) || 'YYYY-MM-DD');
        }
        return moment(this).utc().format(format || 'YYYY-MM-DD');
    }
}
