import React          from 'react';
import { Children }     from 'react';
import { Icon }       from 'antd';
import { Tooltip }        from 'antd';
import connectField   from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';
import joinName       from 'uniforms/joinName';
import format from 'string-format-patterns';

import ListAddField  from './ListAddField';
import ListItemField from './ListItemField';

import { siftQueryMethod } from './patterns';

const List = ({
    children,
    error,
    errorMessage,
    info,
    initialCount,
    itemProps,
    label,
    labelCol,
    name,
    showInlineError,
    value,
    wrapperCol,
    model,
    modelDynamic,
    deleteItem,
    addItem,
    ...props
}) => {

 return <div {...filterDOMProps(props)}>
        {!!label && (
            <div>
                {label}
                {!!info && (
                    <span>
                        &nbsp;
                        <Tooltip title={info}>
                            <Icon type="question-circle-o" />
                        </Tooltip>
                    </span>
                )}
            </div>
        )}

        {!!(error && showInlineError) && (
            <div>
                {errorMessage}
            </div>
        )}

        {children ? (
            value.map((item, index) =>
                Children.map(children, child =>
                    React.cloneElement(child, {
                        key: index,
                        label: null,
                        name: joinName(name, child.props.name && child.props.name.replace('$', index))
                    })
                )
            )
        ) : (
            value.map((item, index) =>
                <ListItemField
                    key={index}
                    label={null}
                    labelCol={labelCol}
                    name={joinName(name, index)}
                    wrapperCol={wrapperCol}
                    model={model}
                    modelDynamic={modelDynamic}
                    deleteItem={typeof(addItem) === 'boolean' ? () => deleteItem : !deleteItem ? () => true : siftQueryMethod(deleteItem.format(modelDynamic))}
                    {...itemProps}
                />
            )
        )}


        <ListAddField
            name={`${name}.$`}
            initialCount={initialCount}
            modelDynamic={modelDynamic}
            addItem={typeof(addItem) === 'boolean' ? () => addItem : !addItem ? () => true : siftQueryMethod(addItem.format(modelDynamic))}
        />
    </div>
}
;

List.defaultProps = {
    style: {
        border: '1px solid #DDD',
        borderRadius: '7px',
        marginBottom: '5px',
        marginTop: '5px',
        padding: '10px'
    }
};

export default connectField(List, {ensureValue: true, includeInChain: false});
