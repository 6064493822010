import React from 'react';
import Loading from './Loading';
import Error from './Error';
import queryString from 'query-string';
import { upsetData } from './dataFunctions';

const loadFeature = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        feature_path: null,
        loading: true,
        feature: null,
        error: null,
        model: null
      };
      this.onSuccess = this.onSuccess.bind(this);
      this.onError = this.onError.bind(this);
      this.dataLoader = this.dataLoader.bind(this);
    }
    componentDidMount() {
      this.dataLoader(this.props);
    }
    componentWillReceiveProps(nextProps) {
      this.dataLoader(nextProps);
    }
    dataLoader(nextProps) {
      if (!this.state.feature_path || nextProps['*'] !== this.props['*']) {
        this.setState({feature_path: nextProps['*'], loading: true});
        upsetData(nextProps['*'], queryString.parse(nextProps.location.search), 'load', nextProps.cookies, this.onSuccess, this.onError);
      }
    }
    onSuccess(feature, model) {
      this.setState({feature, model, loading: false});
    }
    onError(err) {
      this.setState({error: err, loading: false});
    }
    render() {
      // Wraps the input component in a container, without mutating it. Good!
      if (this.state.loading) {
        return (<Loading />);
      }
      if (this.state.error) {
        return (<Error error={this.state.error} />)
      }
      return (<WrappedComponent feature={this.state.feature} model={this.state.model} cookies={this.props.cookies} location={this.props.location} />);
    }
  }
}

export default loadFeature;
