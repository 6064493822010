import React from 'react';
import { SurveyManager } from './uniforms-survey';

export const safeSurveyManager = options => {
    const fields = options.fields.filter(field => field.name).reduce((fields, {name, ...field}) => Object.assign(fields, {[name]: field}), {});
    const pages  = options.pages.map(page => ({...page, fields: page.fields.filter(name => fields[name])}));
    const survey = new SurveyManager({fields, pages});
    survey.__cached = JSON.stringify({fields, pages});
    return survey;
};
