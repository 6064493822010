import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

const Frame = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -30px;
  margin-top: 100px;
  color: #AAA;
`;

const Text = styled.div`
    margin: 15px 6px 10px -80px;
    font-size: 1.2em;
`;
const SmallText = styled.div`
    margin: 0px 6px 10px -80px;
    font-size: 0.7em;
`;


const Spinner = styled.div`
    margin: 10px;
    font-size: 3em;
`;


const ErrorCenterPlain = ({error}) => (
    <Frame>
        <Spinner>
            <Icon type='frown' />
        </Spinner>
        <Text>
          {!error.response ? 'You have a funny internet connection...' : error.response && error.response.status === 500 ? 'Soo Sorry... Stuff went wrong!' : 'Oohh Noo... Your link is broken!'}
        </Text>
        <SmallText>
            Error {error.response ? error.response.status : ''}: {error.response ? error.response.data.message : 'Cannot setup api connection.'}
        </SmallText>
    </Frame>
);

export default ErrorCenterPlain;
