import React from 'react';
import connectField    from 'uniforms/connectField';
import {createElement} from 'react';

import formatFieldToString from './formatFieldToString';

const ReadOnly = (props) => {
    return(
        <div className={'form-bg-light-grey'}>
            <b>{props.label}</b>:
            <div className={'form-bg-dark-grey'}>
                {formatFieldToString(props)}
            </div>
        </div>
    );
}

ReadOnly.defaultProps = {
    tag: 'div'
};

export default connectField(ReadOnly);
