import React from 'react';
import connectField    from 'uniforms/connectField';
import {createElement} from 'react';

import formatFieldToString from './formatFieldToString';

const ReadOnlyInlineItem = (props) => {
    return(
        <div className={'form-bg-light-grey-inline'}>
            <div >
                <b>{props.label}</b>: {createElement('span', {dangerouslySetInnerHTML: {__html: formatFieldToString(props) }})}
            </div>
        </div>
    );
}

ReadOnlyInlineItem.defaultProps = {
    tag: 'div'
};

export default connectField(ReadOnlyInlineItem);
