import React from 'react';
import connectField    from 'uniforms/connectField';
import {createElement} from 'react';

const TextTop = (props) => {
    return(
        <div className={'form-bg-light-grey'} style={{paddingBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 0}}>
            <div className={'form-bg-dark-grey'} style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, marginBottom: 0, paddingBottom: 3}}>
                {createElement(props.tag, {dangerouslySetInnerHTML: {__html: props.html ? props.html : props.label}})}
            </div>
        </div>
    );
}

TextTop.defaultProps = {
    tag: 'div'
};

export default connectField(TextTop);
