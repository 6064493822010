import format from 'string-format-patterns';

const formatFieldToString = (props) =>  {
    if (props.query && props.allowedValues) {
        let item = props.allowedValues.filter(item => item.value === props.value)
        if (item.length === 0) {
            return props.value instanceof Date ? "{0.format('YYYY-MM-DD')}".format(props.value) : props.value;
        }
        return item[0].label;
    }
    return props.value instanceof Date ? "{0.format('YYYY-MM-DD')}".format(props.value) : props.value;
};

// const formatFieldToString = (field) => {
//     let item = '';
//     if (field instanceof Array) {
//         console.log(field);
//         item = item + '<br/>';
//         for (i in field) {
//             item = item + '<br/>' + formatFieldToString(field[i]);
//         }
//     }
//     if (field instanceof Object) {
//         item = item + '<br/>';
//         for (i in field) {
//             item = item + '<br/>' + formatFieldToString(field[i]);
//         }
//     }
//     return item + formatString(field);
// }


export default formatFieldToString;
