import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

const ThankYou_ST = styled.div`
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    font-size: 1.1rem;
    height: calc(100% - 58px - 100px);
`;
const ThankYou_Icon = styled.div`
    font-size: 3rem;
    padding: 10px;
`;


const ThankYou = ({ text, icon }) => (
    <ThankYou_ST >
        <ThankYou_Icon>
            <Icon type={icon} />
        </ThankYou_Icon>
        {text}
    </ThankYou_ST>
);

export default ThankYou;
