import React from 'react';
import { createElement } from 'react';
import invariant       from 'fbjs/lib/invariant';
import connectField    from 'uniforms/connectField';

import BoolField       from './BoolField';
import HiddenField     from './HiddenField';
import TabsField from './TabsField';
import TextField from './TextField';
import NumField from './NumField';
import RadioField from './RadioField';
import SliderField from './SliderField';
import StaticField from './StaticField';
import LongTextField from './LongTextField';
import SelectField from './SelectField';
import ListField from './ListField';
import NestField from './NestField';
import DateField from './DateField';
import ReadOnly from './ReadOnly';
import ReadOnlyTop from './ReadOnlyTop';
import ReadOnlyBottom from './ReadOnlyBottom';
import ReadOnlyMiddle from './ReadOnlyMiddle';
import ReadOnlyText from './ReadOnlyText';
import ReadOnlyTextTop from './ReadOnlyTextTop';
import ReadOnlyTextBottom from './ReadOnlyTextBottom';
import ReadOnlyTextMiddle from './ReadOnlyTextMiddle';
import ReadOnlyContentList from './ReadOnlyContentList';
import ReadOnlyInlineItem from './ReadOnlyInlineItem';
import ContentList from './ContentList';

const Auto = ({component, model, fieldComponent, editComponent, readOnly, readOnlyComponent, ...props}) => {
    if (component === undefined) {
        if (readOnly && readOnly(model)) {
            switch (readOnlyComponent) {
            case 'content_list':
                component = ReadOnlyContentList;
                break;
            case 'list_top':
                component = ReadOnlyTop;
                break;
            case 'list_bottom':
                component = ReadOnlyBottom;
                break;
            case 'list_middle':
                component = ReadOnlyMiddle;
                break;
            case 'text_top':
                component = ReadOnlyTextTop;
                break;
            case 'text_bottom':
                component = ReadOnlyTextBottom;
                break;
            case 'text_middle':
                component = ReadOnlyTextMiddle;
                break;
            case 'text':
                component = ReadOnlyText;
                break;
            case 'single_inline':
                component = ReadOnlyInlineItem;
                break;
            case 'single':
                component = ReadOnly;
                break;
            default:
                component = ReadOnly;
                break;
            }
        } else if (fieldComponent || editComponent) {
            if (props.fieldType === Array && (fieldComponent || editComponent) !== 'foreignmultiselect') {
                if ((fieldComponent || editComponent) === 'tabs') {
                    component = TabsField;
                } else {
                    component = ListField;
                }
            } else {
                const c = (fieldComponent || editComponent);
                switch (c) {
                case 'hidden':
                    component = HiddenField;
                    break;
                case 'slider':
                    component = SliderField;
                    break;
                case 'content_list':
                    component = ContentList;
                    break;
                }

                invariant(component, 'Unsupported field component: %s', fieldComponent || editComponent);
            }
        } else if (props.allowedValues) {
            if (props.checkboxes && props.fieldType !== Array) {
                component = RadioField;
            } else {
                component = SelectField;
            }
        } else if (props.html) {
            component = StaticField;
        } else {
            switch (props.fieldType) {
            case Date:    component = DateField; break;
            case Array:   component = ListField; break;
            case Number:  component = NumField; break;
            case Object:  component = NestField; break;
            case String:  component = props.rows ? LongTextField : TextField; break;
            case Boolean: component = BoolField; break;
            }

            invariant(component, 'Unsupported field type: %s', props.fieldType.toString());
        }
    }
    //  TODO: when updateing to React 16, we can catch which fields produces errors.. see: https://github.com/facebook/react/issues/2461
    return createElement(component, props);
};

const AutoGl = (props) => {
    return <Auto {...props} />;
};

export default connectField(AutoGl, {ensureValue: false, includeInChain: false, initialValue: false});
