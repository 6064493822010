import React from 'react';
import { createElement } from 'react';
import connectField from 'uniforms/connectField';
import { Avatar } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import wrapField from './wrapField';

// {
// "name": "__fieldname__",
// "type": "string",
// "optional": true,
// "readOnlyIf": true,
// "readOnlyComponent": "content_list",
// "props": {
//     "label": "Heading for the list",
//     "errorMessage": "none",
//     "query": {
//         "returnType": "array",
//         "query_id": "G9K32mhr3TA6T6fSj",
//         "queryProp": "listElements",
//         "linkedFields": ["channel"]
//     },
//     "listHeight": 400,
//     "listElements": [{
//         "avatar": "NONE",
//         "backgroundColor": "avatar color",
//         "title": "No Items Found",
//         "description": '-',
//         "key": "item key or id",
//         "content": "A line of html text",
//         "gridContent": [
//             ['', 'title 1', 'title 2'],
//             ['sales', 1, 2],
//             ['target', 1.234, 2.0002]
//
//         ]
//     }]
// }

const ReadOnlyContentList = (props) => (
    <div>
        <div style={{whiteSpace: 'normal', textAlign: 'left'}} >{props.label}</div>
        <div style={{margin: '15px 0px', borderTop: '1px solid #d9d9d9'}}></div>
        {props.listElements &&  props.listElements.length ?
            <div style={{overflowY: 'scroll', maxHeight: props.listHeight ? props.listHeight : 400}}>
                {props.listElements.map((item, r)=>(
                    <div key={r}>
                        {(item.avatar || item.avatar === 0) && <div style={{float: 'left'}}>
                            <Avatar shape="square" size="large" style={{ backgroundColor: item.backgroundColor }} >{item.avatar}</Avatar>
                        </div>}
                        <div style={{lineHeight: 1.3, margin: '5px 45px', minHeight: 40}}>
                            {item.title && <div style={{margin: 5}}>{createElement('span', {dangerouslySetInnerHTML: {__html: item.title}})}</div>}
                            {item.description && <div style={{color: '#b3b3b3', fontSize: 'smaller', margin: 5}}>{createElement('span', {dangerouslySetInnerHTML: {__html: item.description}})}</div>}
                            {item.content && <div style={{fontSize: 'smaller', margin: 5}}>{createElement('span', {dangerouslySetInnerHTML: {__html: item.content}})}</div>}
                            {item.gridContent && <div style={{fontSize: 'smaller', margin: 5}}>{item.gridContent.map((row, i)=>{
                                if (!row || row.length === 0){
                                    return (<div key={i} style={{margin: 5}}></div>);
                                }
                                return (<Row key={i} gutter={3}>
                                    {row.map((col, k)=>(
                                        <Col key={k} span={props.colSpan && Array.isArray(props.colSpan) ? props.colSpan[k] : Math.floor(24/(row.length))}>{createElement('span', {dangerouslySetInnerHTML: {__html: col}})}</Col>
                                    ))}
                                </Row>);
                            })}</div>}
                        </div>
                        <div style={{margin: '15px 0px', borderTop: '1px solid #d9d9d9'}}></div>
                    </div>
                )) }
            </div>
            : <div style={{textAlign: 'center', color: '#d9d9d9', fontWeight: 600, fontSize: 'larger'}} >No Data</div>
        }
    </div>
);

ReadOnlyContentList.defaultProps = {
    pagination: false,
    listElements: [],
    label: 'No Label'
};

export default connectField(ReadOnlyContentList);
