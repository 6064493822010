import React        from 'react';
import connectField from 'uniforms/connectField';
import joinName     from 'uniforms/joinName';
import {Children}   from 'react';

import AutoField    from './AutoField';
import ListDelField from './ListDelField';

const ListItem = ({deleteItem, ...props}) => {
    return (
        <div>
            {deleteItem(props.modelDynamic) ?
                <div
                    style={{
                        float: 'right',
                        marginTop: '-10px',
                        marginLeft: '10px',
                        marginRight: '6px',
                    }}
                >
                    <ListDelField className="top aligned" name={props.name} />
                </div>
                : null
            }

            <div style={{margin: '10px 0px', overflow: 'hidden', borderBottom: '1px solid #DDD'}}>
            </div>

            <div style={{width: '100%'}}>
                {props.children ? (
                    Children.map(props.children, child =>
                        React.cloneElement(child, {
                            name: joinName(props.name, child.props.name),
                            label: null
                        })
                    )
                ) : (
                    <AutoField {...props} />
                )}
            </div>
        </div>
    );
}

;

export default connectField(ListItem, {includeInChain: false, includeParent: true});
