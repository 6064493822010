import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import get from 'lodash.get';

import Header from './Header';
import Footer from './Footer';
import Message from './Message';

const Container = styled.div`
    height: 100%;
    position: relative;
`;

const Layout = ({ children, feature, model }) => (
    <Container>
        <Header feature={feature} />
        {
          get(model, 'model_status.message', false) ?
          React.createElement(Message, {text: get(model, 'model_status.message', 'Thank You!'), icon: get(model, 'model_status.icon', 'check-circle') })
          :
          children
        }
        <Footer feature={feature} />
    </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  feature: PropTypes.node.isRequired,
}

export default Layout
