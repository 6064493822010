import format from 'string-format-patterns';

export const subLabelFields = (sample, feature) => {
    const fields = [];
    feature.fields.forEach(field=>{
        const fl = {...field, props: {...field.props}};
        if (fl.props && fl.props.label) {
            fl.props.label = fl.props.label.format(sample);
        }
        if (fl.props && fl.props.html) {
            fl.props.html = fl.props.html.format(sample);
        }
        if (fl.visibleIf) {
            fl.visibleIf = fl.visibleIf.format(sample);
        }
        fields.push(fl);
    });
    return {...feature, fields};
}
