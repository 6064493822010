import React          from 'react';
import { Input }          from 'antd';
import connectField   from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';

import wrapField from './wrapField';

const Text = props =>
    wrapField({feedbackable: true, ...props, bsClass: 'form-group-html'}, (
        <Input
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            onChange={event => props.onChange(event.target.value)}
            placeholder={props.placeholder}
            ref={props.inputRef}
            autoComplete="off"
            value={props.value}
            {...filterDOMProps(props)}
        />
    ))
;

Text.defaultProps = {type: 'text'};

export default connectField(Text);
