import React        from 'react';
import { Radio }   from 'antd';
import connectField from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';

import wrapField from './wrapField';

// TODO: PR these changes to uniforms-antd to handel label value objs
const Radio_ = props =>
    wrapField(props, (
        <Radio.Group
            disabled={props.disabled}
            name={props.name}
            onChange={event => props.onChange(event.target.value)}
            value={props.value}
            buttonStyle='solid'
            {...filterDOMProps(props)}
        >
            {props.allowedValues.filter(item => !item.hide).map(obj =>
                <Radio.Button
                    key={obj.value ? obj.value : obj}
                    value={obj.value ? obj.value : obj.value === false ? false : obj}
                >
                    {props.transform ? props.transform(obj.label ? obj.label : obj) : obj.label ? obj.label : obj}
                </Radio.Button>
            )}
        </Radio.Group>
    ))
;

Radio_.displayName = 'Radio';

export default connectField(Radio_);
