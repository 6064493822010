import React          from 'react';
import { Input }        from 'antd';
import connectField   from 'uniforms/connectField';
import filterDOMProps from 'uniforms/filterDOMProps';

import wrapField from './wrapField';

const { TextArea } = Input;

const LongText = props => wrapField(props, (
    <TextArea
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        onChange={event => props.onChange(event.target.value)}
        placeholder={props.placeholder}
        ref={props.inputRef}
        type="textarea"
        autoComplete="off"
        value={props.value}
        rows={props.rows}
        {...filterDOMProps(props)}
    />
));

LongText.defaultProps = {rows: 5};

export default connectField(LongText);
